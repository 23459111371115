import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { useAuth0 } from '../../auth';
import { config } from '../../config/environment';
import { useCurrentUser } from '../../hooks/use-current-user/use-current-user';
import '../../utils/logrocketInit.ts';

interface IdentityProviderProps {
  children: React.ReactNode;
}

export const IdentityProvider = (props: IdentityProviderProps) => {
  const { user } = useAuth0();
  const { data, loading } = useCurrentUser();
  const userId = !loading && data?.id && user?.sub ? user?.sub : null;

  const userDataJson = userId
    ? JSON.stringify({
        email: data?.email,
        firstName: data?.firstName,
        lastName: data?.lastName,
        phone: data?.phone,
        nickname: data?.profile?.nickname,
        picture: data?.profile?.avatar?.url,
      })
    : null;

  useEffect(() => {
    if (!userId || !userDataJson) {
      return;
    }

    const userData = JSON.parse(userDataJson);

    if (config.logRocketId) {
      LogRocket.identify(userId, userData);
    }

    if (config.segmentKey && typeof window.analytics !== 'undefined') {
      window.analytics.identify(userId, userData);
    }
  }, [userId, userDataJson]);

  return <>{props.children}</>;
};
