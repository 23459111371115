import { css, Global } from '@emotion/react';
// @ts-ignore
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import { useEffect } from 'react';

const fancyboxStyles = css`
  .fancybox__container {
    z-index: 2000;
  }

  html.with-fancybox {
    overflow: hidden;
  }
`;

type FancyboxAction = 'close' | 'next' | 'prev';
type FancyboxCloseButtonPosition = 'inside' | 'outside';

type FancyboxOptions = {
  // Index of active slide on the start
  startIndex?: number;

  // Number of slides to preload before and after active slide
  preload?: number;

  // Should navigation be infinite
  infinite?: boolean;

  // Class name to be applied to the content to reveal it
  showClass?: string; // "fancybox-fadeIn" | "fancybox-zoomInUp" | false

  // Class name to be applied to the content to hide it
  hideClass?: string; // "fancybox-fadeOut" | "fancybox-zoomOutDown" | false

  // Should backdrop and UI elements fade in/out on start/close
  animated?: boolean;

  // If browser scrollbar should be hidden
  hideScrollbar?: boolean;

  // Custom class name or multiple space-separated class names for the container
  mainClass?: string;

  // Set focus on first focusable element after displaying content
  autoFocus?: boolean;

  // Trap focus inside Fancybox
  trapFocus?: boolean;

  // Set focus back to trigger element after closing Fancybox
  placeFocusBack?: boolean;

  // Action to take when the user clicks on the backdrop
  click?: FancyboxAction;

  // Position of the close button - over the content or at top right corner of viewport
  closeButton?: FancyboxCloseButtonPosition;

  // Allow user to drag content up/down to close instance
  dragToClose?: boolean;

  // Enable keyboard navigation
  keyboard?: {
    Escape?: FancyboxAction;
    Delete?: FancyboxAction;
    Backspace?: FancyboxAction;
    PageUp?: FancyboxAction;
    PageDown?: FancyboxAction;
    ArrowUp?: FancyboxAction;
    ArrowDown?: FancyboxAction;
    ArrowRight?: FancyboxAction;
    ArrowLeft?: FancyboxAction;
  };

  // HTML templates for various elements
  template?: {
    // Close button icon
    closeButton?: string;
    // Loading indicator icon
    spinner?: string;
    // Main container element
    main?: string;
  };
};

type FancyboxProviderProps = {
  children?: React.ReactNode;
  delegate?: string;
  options?: FancyboxOptions;
};

const defaultOptions = {
  Html: {
    youtube: {
      autohide: 1,
      fs: 1,
      rel: 0,
      hd: 1,
      wmode: 'transparent',
      enablejsapi: 1,
      html5: 1,
      cc_load_policy: 1,
      showinfo: 0,
      modestbranding: 1,
    },
  },
};

export const FancyboxProvider = ({
  options,
  delegate,
  children,
}: FancyboxProviderProps): React.ReactElement => {
  useEffect(() => {
    const opts = {
      ...defaultOptions,
      ...options,
    };
    NativeFancybox.bind(delegate || '[data-fancybox]', opts);
    return () => {
      NativeFancybox.destroy();
    };
  }, [delegate, options]);

  return (
    <>
      <Global styles={fancyboxStyles} />
      {children}
    </>
  );
};
